import { Link } from "@gadgetinc/widgets";
import { useStyletron } from "baseui";
import React from "react";

export const LegalTermsFooter = () => {
  const [css, $theme] = useStyletron();
  return (
    <footer
      className={css({
        marginTop: $theme.sizing.scale600,
        color: $theme.colors.primary600,
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
      })}
    >
      By continuing, I agree to Gadget&lsquo;s <Link href="https://gadget.dev/terms">Terms and Conditions</Link> and{" "}
      <Link href="https://gadget.dev/privacy">Privacy Policy</Link>
    </footer>
  );
};
